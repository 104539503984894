<template>
<v-card elevation="0">
    <v-card-text>
    <v-data-table :headers="headers"
        :items="broadcastlog"
        class="elevation-1"
        sort-by="timestamp"
        sort-desc
    >
        <template v-slot:top>
        <v-toolbar flat>
            <v-toolbar-title>Give Vouchers</v-toolbar-title>
            <v-divider class="mx-4" inset vertical></v-divider>
            <v-text-field v-model="keyword" placeholder="Cari..." hide-details></v-text-field>
            <v-spacer></v-spacer>
            <v-dialog scrollable v-model="dialog" max-width="700px">
                <template v-slot:activator="{ on, attrs }">
                    <v-btn color="primary" dark class="mb-2" v-bind="attrs" v-on="on">
                        Give Voucher
                    </v-btn>
                </template>
                <v-card>
                    <v-card-title>
                    <span class="headline">Give Voucher</span>
                    </v-card-title>

                    <v-card-text>
                    <v-container>
                        <v-row>
                            <v-col cols="12" sm="12" md="12">
                                <v-autocomplete outlined dense :items="vouchers" item-value="code"
                                :item-text="(row) => {return row.code+ ' - ' + row.voucher_desc;}"
                                v-model="editedItem.voucher_code" label="Voucher" hide-details="auto">
                                </v-autocomplete>
                            </v-col>
                            <v-col cols="12" sm="12" md="12">
                              <span class="">Give Voucher To</span>
                              <v-radio-group v-model="editedItem.sendto" row dense hide-details>
                                <v-radio label="All Users" value="all"></v-radio>
                                <v-radio label="Specific Users" value="users"></v-radio>
                                <v-radio label="All Users yang pernah order pada spot tertentu" value="groupusers"></v-radio>
                              </v-radio-group>       
                            </v-col>
                            <v-col cols="12" sm="12" md="12" v-if="editedItem.sendto=='users'">
                              <v-autocomplete outlined dense v-model="editedItem.users" :items="users" 
                                label="Users" item-text="name" item-value="_id" multiple>
                                <template v-slot:item="data">
                                    {{ data.item.name }} - {{ data.item.email }}
                                </template>
                              </v-autocomplete>
                            </v-col>
                            <v-col cols="12" sm="12" md="12" v-if="editedItem.sendto=='groupusers'">
                                <span class="">Activity</span>
                                <v-autocomplete outlined dense :items="activities" item-text="name" item-value="_id" :rules="reqRule"
                                v-model="editedItem.ac_id" hide-details="auto" @change="onActivitySelected"></v-autocomplete>
                            </v-col>
                            <v-col cols="12" sm="12" md="12">
                                <v-textarea v-model="editedItem.notification_content" label="Notification Content" outlined hide-details></v-textarea>
                                <span class="caption">Notifikasi akan dikirim saat voucher diberikan</span>
                            </v-col>
                        </v-row>
                    </v-container>
                    </v-card-text>

                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="blue darken-1" text @click="close">
                            Cancel
                        </v-btn>
                        <v-btn color="blue darken-1" text @click="send">
                            Send
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </v-toolbar>
        </template>
        <template v-slot:no-data>
            <v-btn color="primary" @click="initialize">
                Reset
            </v-btn>
        </template>
      </v-data-table>
    </v-card-text>
    <v-overlay :value="onProgress" style="z-index:999">
        <v-progress-circular :size="50" color="amber" indeterminate></v-progress-circular>
    </v-overlay>  
</v-card>
</template>

<script>
  export default {
    data: () => ({
      dialog: false,
      headers: [
        {
          text: 'Send To', align: 'start', value: 'sendto',
        },
        { text: 'Content', value: 'notification_content' },
        { text: 'Time', value: 'timestamp' },
      ],
      editedIndex: -1,
      editedItem: {
        sendto: '',
        notification_content: '',
      },
      defaultItem: {
        sendto: '',
        notification_content: '',
      },
      entries: [],
      isLoading: false,
      model: null,
      keyword: '',
      broadcastlog: [],
      descriptionLimit: 100,
      onProgress: false,
      vouchers: [],
      users: []
    }),

    computed: {
      apihost() {
        return this.$store.state.config.apiHost
      },
      activities () {
        return this.$store.state.activity.objs
      },
    },

    created () {
      this.initialize()
    },


    methods: {
      async initialize () {
        this.$store.dispatch('voucher/getObjs', 'vouchers').then(response => {
            this.vouchers = response
        })

        this.$store.dispatch('voucher/getObjs', 'getvouchergiveaway').then(response => {
            this.broadcastlog = response
        })

        this.$store.dispatch('user/getObjs').then(response => {
            this.users = response
        })

        await this.$store.dispatch('activity/getObjs')

      },

      editItem (item) {
        this.editedIndex = this.broadcastlog.indexOf(item)
        this.editedItem = Object.assign({}, item)
        this.dialog = true
      },


      close () {
        this.dialog = false
        this.$nextTick(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
        })
      },


      async send () {
        console.log(this.editedItem)
        this.onProgress = true
        const docs = await this.$store.dispatch('voucher/postObj', {pload:this.editedItem, module:'givevouchers'})
        console.log(docs)
        this.dialog = false
        this.onProgress = false
        this.initialize()
      },
    },

    watch: {
      dialog (val) {
        val || this.close()
      },

    },    
  }
</script>